// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   kicker -> text
//   headline -> text / Überschrift
//   headline_level -> option / Überschriftengröße
//   subheadline -> text / Unterzeile
//   text -> markdown / Text
//   video_url -> text / Video-URL
//   caption -> textarea / Bildbeschreibung (optional)
// end of automatic

import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import VideoFigure from "../molecules/VideoFigure"
import HeadlineEnhanced from "../molecules/HeadlineEnhanced"
import TextReveal from "../reveals/TextReveal"

const TextVideoBlock = ({ block }) => {
  return (
    <BlockWrapper block={block} showHeadline="false">
      <div className={`flex flex-row flex-wrap -mx-grid`}>
        <div className="w-full lg:w-1/2 px-grid">
          <VideoFigure
            className="mb-4 lg:mb-0"
            video_url={block.video_url}
            ratio={block.ratio}
            _uid={block._uid}
            caption={block.caption}
          />
        </div>
        <div
          className={`w-full mb-4 lg:w-1/2 px-grid ${
            block.text_position === "left" ? "lg:order-first" : ""
          }`}
        >
          <HeadlineEnhanced
            kicker={block.kicker}
            headline={block.headline}
            headlineLevel={block.headline_level}
            subheadline={block.subheadline}
          />
          <TextReveal>
            <Richtext
              text={block.text}
              className={
                block.headline?.length > 0 || block.subheadline?.length > 0
                  ? "pt-8"
                  : ""
              }
            />
          </TextReveal>
        </div>
      </div>
    </BlockWrapper>
  )
}

export default TextVideoBlock
